
          @import "./src/styles/mixins.scss";
          @import "./src/styles/fonts.scss";
        
.content {
  width: 100%;
  padding: 64px 0 16px;

  .header {
    color: #2f9e14;
  }

  .info {
    margin-top: 16px;
  }
}